<template>
  <!-- <div class="container-fluid"> -->
  <div class="row">
    <div class="col-12" v-if="showSection">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row mt-1">
        <div class="col-md-6 offset-md-3">
          <main class="mt-3 w-100 m-auto">
            <!-- <SignIn></SignIn> -->
            <SignIn></SignIn>
          </main>
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="row row-cols-1 mt-5">
        <div class="alert alert-danger mt-1" role="alert">網站服務調整 (11/1起) <a href="https://www.facebook.com/just.bk.me/posts/868366018794844" target="_blank">見詳細公告</a></div>
      </div>
      <!-- <div class="row row-cols-1 mt-1">
        <main class="mt-3 w-auto m-auto">
          <UserProfilePage :memberProfile="rows5"></UserProfilePage>
        </main>
      </div> -->
    </div>
    <!-- <div class="col-12" v-if="queryform.op === 'unsubscribe' || !showSection">
      <div class="row row-cols-1 mt-5">
        <main class="w-75 m-auto">
          <form class="">
            <div class="row row-cols-1 row-cols-lg-2 g-4 align-items-center">
              <div class="col-12">
                <label class="visually-hidden" for="inlineFormInputGroupUsername">E-mail</label>
                <div class="input-group">
                  <div class="input-group-text">@</div>
                  <input type="text" class="form-control" id="inlineFormInputGroupUsername" placeholder="E-mail" v-model="rows.email" required />
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn btn-danger" @click="unsubscribeNotify(rows.uuid)">取消訂閱</button>
              </div>
            </div>
          </form>
        </main>
        <section class="mt-3 w-75 m-auto">
          <ul class="list-unstyled">
            <li>
              說明:
              <ul>
                <li class="mt-2">一旦取消訂閱，包含已經綁定的 LINE Notify 以及票價追蹤規則也會一併解除綁定</li>
                <li class="mt-2">若日後再重新訂閱，LINE Notify 及票價追蹤規則都必須要重新綁定或建立</li>
              </ul>
            </li>
          </ul>
          <hr />
        </section>
      </div>
    </div> -->
    <div class="col-12" v-if="queryform.op === 'notify_manage'">
      <div class="row row-cols-1 mt-4">
        <section class="mt-1 w-75 m-auto">
          <div class="row row-cols-1 row-cols-lg-1 g-4 align-items-center">
            <div class="col-12 shadow p-3 bg-body rounded g-light bg-gradient" v-if="rows5.members_role_take.role_type === 'Sponsored'">
              <button type="button" v-if="notify_channel_count > 0" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#getlineNotifyModal">綁定 LINE Notify</button>
              <button type="button" v-else class="btn btn-outline-secondary" disabled>額度用完，暫時無法新增頻道</button>
              <main class="w-100 m-auto">
                <p class="h3 mt-3 mb-3">
                  已綁定的頻道
                  <small class="fs-5">
                    剩餘 <span>{{ notify_channel_count }}</span>
                  </small>
                </p>
                <template v-if="rows3.data">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <!-- <th scope="col">建立時間</th> -->
                          <th scope="col">頻道名稱</th>
                          <th scope="col">半夜*通知?</th>
                          <th scope="col">站方推薦*?</th>
                          <th scope="col">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, key) in rows3.data" :key="key">
                          <!-- <th scope="row">{{ item.created_at | formatDate }}</th> -->
                          <th scope="row">
                            {{ item.nickname }}
                            <p>
                              <small>{{ item.created_at | formatDate }}</small>
                            </p>
                          </th>
                          <td><input class="form-check-input" type="checkbox" :checked="item.is_notify_midnight" @change="notify_switch_status('notify_midnight', item.uuid, !item.is_notify_midnight)" /></td>
                          <td>
                            <input class="form-check-input" type="checkbox" :checked="item.is_admin_recommend" @change="notify_switch_status('admin_recommend', item.uuid, !item.is_admin_recommend)" />
                            <!-- <p v-if="item.recommend_expiry_dt !== null">
                              <small>若須取消，還請於 {{ item.recommend_expiry_dt | formatDate }} 後再執行</small>
                            </p> -->
                          </td>
                          <td><button type="button" class="btn btn-danger" @click="removeNotifyChannel(item.uuid)">解除</button></td>
                        </tr>
                      </tbody>
                    </table>
                    <ul>
                      <li class="mt-1 fs-5 text-danger">由於 LINE 官方已宣佈 2025/3/31 起結束 LINE Notify 服務 (請詳見<a href="https://notify-bot.line.me/closing-announce" target="_blank">官方公告</a>)，本站此功能也將同步於 2025/3/31 下線，相關資料也將一併刪除不予以保留</li>
                      <li>半夜定義: 台灣時間 00:00 ~ 01:00，期間僅發送一次為原則，勾選後也請告知該群組內其他成員，以避免造成群組內成員半夜休息時困擾</li>
                      <li class="mt-1">
                        推薦資訊種類 <code><B>(包括但不限於)</B></code
                        >：夏冬季班表開放訂位、特殊資訊(如票價、漲降等等)
                      </li>
                      <li class="mt-1">只要是剛綁定的頻道，系統會要求同時綁定站方推薦一週，一週後可視需要自行取消 (以系統顯示之到期時間為準)，不便之處還請見諒</li>
                      <li class="mt-1 fs-5 text-danger">LINE Notify 綁定後也需要同時綁定底下追蹤規則唷！這樣符合追蹤條件的結果才會傳送到你所指定的群組中</li>
                    </ul>
                  </div>
                </template>
                <template v-if="!rows3.data">
                  <div class="alert alert-warning" role="alert">目前並沒有綁定任何頻道</div>
                </template>
              </main>
            </div>
            <div class="col-12 d-none d-lg-block shadow p-3 bg-body rounded g-light bg-gradient">
              <button type="button" v-if="notify_rule_count > 0" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#farehistoryModal">新增/修改票價追蹤規則</button>
              <button type="button" v-else class="btn btn-outline-secondary" disabled>額度用完，暫時無法新增規則</button>
              <main class="w-100 m-auto">
                <p class="h3 mt-3 mb-3">
                  已綁定的追蹤規則
                  <small class="fs-5">
                    剩餘 <span>{{ notify_rule_count }}</span>
                  </small>
                </p>
                <template v-if="rows2[rows.email]">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">建立時間</th>
                          <th scope="col">追蹤對象</th>
                          <th scope="col">規則1</th>
                          <th scope="col">規則2</th>
                          <th scope="col">通知管理</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, key) in rows2[rows.email]" :key="key">
                          <td><button type="button" class="btn btn-danger" @click="removeNotifyRule(item.uuid)">刪除</button></td>
                          <th scope="row">{{ item.created_at | formatDate }}</th>
                          <td>
                            <span v-if="item.rule_airline == ''">不指定</span> <span v-else>{{ item.rule_airline }}</span>
                          </td>
                          <td>
                            <span v-html="getRuleContent1(item.rule_travel_type, item.rule_dept_d, item.rule_arri_d, item.rule_dept, item.rule_arri)"></span><span class="badge text-bg-light fs-6">{{ item.rule_flight_no }}</span>
                          </td>
                          <td><span v-html="getRuleContent2(item.rule_mode, item.rule_currency, item.rule_fare1, item.rule_fare2, item.rule_fare_base)"></span></td>
                          <td>
                            <button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#getlineNotifyModal2" :data-bs-item-id="item.id">設定</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <template v-if="!rows2[rows.email]">
                  <div class="alert alert-warning" role="alert">目前並沒有綁定任何票價追蹤</div>
                </template>
              </main>
            </div>
            <div class="col-12 d-lg-none shadow p-3 bg-body rounded g-light bg-gradient">
              <button type="button" v-if="notify_rule_count > 0" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#farehistoryModal">新增/修改票價追蹤規則</button>
              <button type="button" v-else class="btn btn-outline-secondary" disabled>額度用完，暫時無法新增規則</button>
              <main class="w-100 m-auto">
                <p class="h3 mt-3 mb-3">
                  已綁定的追蹤規則
                  <small class="fs-5">
                    剩餘 <span>{{ notify_rule_count }}</span>
                  </small>
                </p>
                <template v-if="rows2[rows.email]">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">規則1</th>
                          <th scope="col">規則2</th>
                        </tr>
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th scope="col">建立時間</th>
                          <th scope="col">追蹤對象</th>
                          <!-- <th scope="col">規則1</th> -->
                          <!-- <th scope="col">規則2</th> -->
                          <th scope="col">通知管理</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(item, key) in rows2[rows.email]">
                          <tr :key="key">
                            <td><button type="button" class="btn btn-danger" @click="removeNotifyRule(item.uuid)">刪除</button></td>
                            <td>
                              <span v-html="getRuleContent1(item.rule_travel_type, item.rule_dept_d, item.rule_arri_d, item.rule_dept, item.rule_arri)"></span><span class="badge text-bg-light fs-6">{{ item.rule_flight_no }}</span>
                            </td>
                            <td><span v-html="getRuleContent2(item.rule_mode, item.rule_currency, item.rule_fare1, item.rule_fare2, item.rule_fare_base)"></span></td>
                          </tr>
                          <tr :key="key">
                            <!-- <td><button type="button" class="btn btn-danger" @click="removeNotifyRule(item.uuid)">刪除</button></td> -->
                            <th scope="row">{{ item.created_at | formatDate }}</th>
                            <td>
                              <span v-if="item.rule_airline == ''">不指定</span> <span v-else>{{ item.rule_airline }}</span>
                            </td>
                            <td>
                              <button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#getlineNotifyModal2" :data-bs-item-id="item.id">設定</button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </template>
                <template v-if="!rows2[rows.email]">
                  <div class="alert alert-warning" role="alert">目前並沒有綁定任何票價追蹤</div>
                </template>
              </main>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">操作說明</button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="lead">Just.崩潰（以下稱本站）提供二種票價追蹤模式：目前、趨勢，兩者說明如下</p>
                    <dt class="col-sm-3">目前</dt>
                    <dd class="col-sm-9">會根據最新一次的價格資料去比對</dd>
                    <dt class="col-sm-3">趨勢</dt>
                    <dd class="col-sm-9">會根據最新一次的價格資料去比對前一次價格資料</dd>
                    <hr />
                    <p class="lead">
                      通知方式提供四種規則：低於設定價、高於設定價、介於設定價之間以及不管高或低一律通知，根據所設定的追蹤模式，其意思會有所不同，此外設定價的價格基準為「<span class="text-danger"><b>單程未稅 TWD</b></span
                      >」
                    </p>
                    <p>
                      當追蹤模式選擇「<span class="text-danger"><b>目前</b></span
                      >」時，例如設定價為 2999
                    </p>
                    <ul>
                      <li>
                        低於設定價才通知：也就是最新一筆的票價資料必須「<span class="text-danger"><b>低於</b></span
                        >」 2999，本站才會寄發通知給你
                      </li>
                      <li>
                        高於設定價才通知：也就是最新一筆的票價資料必須「<span class="text-danger"><b>高於</b></span
                        >」 2999，本站才會寄發通知給你
                      </li>
                      <li>
                        介於設定價之間才通知：例如設定價為 3299 至 4299 話，也就是最新一筆的票價資料必須「<span class="text-danger"><b>介於</b></span
                        >」 3299 至 4299 之間，系統才會寄發通知給你
                      </li>
                      <li>不管高或低一律通知：即便價格沒有變動，本站都會寄發通知給你</li>
                    </ul>
                    <hr />
                    <p>
                      當追蹤模式選擇「<span class="text-danger"><b>趨勢</b></span
                      >」時，此時設定價的意思就會是漲降幅的範圍，例如設定價 1500
                    </p>
                    <ul>
                      <li>
                        低於設定價才通知：也就是最新一筆的票價資料跟前一次的價格比對後，必須「<span class="text-danger"><b>低於</b></span
                        >」 1500，本站才會寄發通知給你
                      </li>
                      <li>
                        高於設定價才通知：也就是最新一筆的票價資料跟前一次的價格比對後，必須「<span class="text-danger"><b>高於</b></span
                        >」 1500，本站才會寄發通知給你
                      </li>
                      <li>
                        介於設定價之間才通知：例如設定價為 1500 至 2500 話，也就是最新一筆的票價資料跟前一次的價格比對後，必須「<span class="text-danger"><b>介於</b></span
                        >」 1500 至 2500 之間，系統才會寄發通知給你
                      </li>
                      <li>不管高或低一律通知：不管漲幅、降幅多少甚或沒變動，本站都會寄發通知給你</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="modal fade modal-xl" id="farehistoryModal" tabindex="-1" aria-labelledby="farehistoryModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="helpModalLabel">新增/修改票價追蹤規則</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <form @submit.prevent="getFormValues">
                  <fieldset class="row mb-3">
                    <legend class="col-form-label col-sm-2 pt-0">通知模式</legend>
                    <div class="col-sm-12">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.mode" type="radio" name="mode" id="mode1" value="now" checked required />
                        <label class="form-check-label" for="mode1"> 目前 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.mode" type="radio" name="mode" id="mode2" value="trend" required />
                        <label class="form-check-label" for="mode2"> 趨勢 </label>
                      </div>
                      <!-- <div class="form-check form-check-inline disabled">
                        <input class="form-check-input" v-model="ruleform.mode" type="radio" name="mode" id="mode3" value="report" disabled />
                        <label class="form-check-label" for="mode3"> 報表 </label>
                      </div> -->
                    </div>
                  </fieldset>
                  <!-- <hr /> -->
                  <fieldset class="row mb-3">
                    <legend class="col-form-label col-sm-2 pt-0">通知方式</legend>
                    <div class="col-sm-12">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base1" value="under" @click="open_fare(true, 'under')" required />
                        <label class="form-check-label" for="fare_base1"> 低於設定價才通知 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base2" value="above" @click="open_fare(true, 'above')" required />
                        <label class="form-check-label" for="fare_base2"> 高於設定價才通知 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base3" value="between" @click="open_fare(false, 'between')" required />
                        <label class="form-check-label" for="fare_base3"> 介於設定價之間才通知 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base4" value="none" @click="disable_fare" required />
                        <label class="form-check-label" for="fare_base4"> 不管高或低一律通知 </label>
                      </div>
                    </div>
                  </fieldset>
                  <hr />
                  <div class="row mb-3">
                    <B><code>目前開放對象 樂桃航空(MM)、全亞洲航空 (D7)、泰國亞洲航空 (FD)、酷航 (TR)、泰國獅子航空 (SL)、泰國越捷航空 (VZ)、馬來西亞峇迪航空 (OD)</code></B>
                  </div>

                  <div class="row mb-3">
                    <div class="col-lg-4 col-sm-12">
                      <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">航空業者</small></label>
                      <div class="input-group">
                        <div class="input-group-text">
                          <font-awesome-icon icon="plane-departure" />
                        </div>

                        <select class="form-control form-select input-height" name="" v-model="ruleform.airline">
                          <option value="" selected>全部</option>
                          <!-- <option value="IT">台灣虎航 (IT)</option> -->
                          <option value="MM">樂桃航空 (MM)</option>
                          <option value="D7">全亞洲航空 (D7)</option>
                          <option value="FD">泰國亞洲航空 (FD)</option>
                          <option value="TR">酷航 (TR)</option>
                          <option value="SL">泰國獅子航空 (SL)</option>
                          <option value="VZ">泰國越捷航空 (VZ)</option>
                          <option value="OD">馬來西亞峇迪航空 (OD)</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                      <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">出發地</small></label>
                      <div class="input-group">
                        <div class="input-group-text">
                          <font-awesome-icon icon="plane-departure" />
                        </div>

                        <select class="form-control form-select input-height" name="" v-model="countryName" @change="selectCity" required>
                          <option :value="item" v-for="(item, index) in area" :key="index" :selected="selectedDay === item.country">
                            {{ item.country }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                      <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">目的地</small></label>
                      <div class="input-group">
                        <div class="input-group-text">
                          <font-awesome-icon icon="plane-arrival" />
                        </div>
                        <select class="form-control form-select input-height" name="" v-model="cityName" @change="setCountryCity" required>
                          <option :value="item" v-for="(item, index) in countryName.city" :key="index">
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-6">
                      <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">請選擇</small></label>
                      <div class="input-group">
                        <div class="input-group-text">
                          <i class="bi bi-arrow-left-right"></i>
                        </div>
                        <select class="form-control form-select input-height" id="select1" v-model="ruleform.travel_type" required>
                          <!-- <option value="" disabled></option> -->
                          <option value="OW">單程</option>
                          <option value="RT">來回</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">旅行日期</small></label>
                      <SearchFromV3 @input="getD2" v-model="ruleform.dateRange2" :accept="ruleform.travel_type"></SearchFromV3>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-3 col-form-label">設定價 (單程未稅 TWD)</label>
                    <template v-if="awesome">
                      <div class="col-sm-9">
                        <input type="number" v-model.number="ruleform.fare1" class="form-control" id="fare1" :disabled="isDisabled" required />
                      </div>
                    </template>
                    <template v-else>
                      <div class="input-group col-sm-10">
                        <input type="number" v-model.number="ruleform.fare1" class="form-control" aria-label="fare1" min="1" :disabled="isDisabled" required />
                        <span class="input-group-text">~</span>
                        <input type="number" v-model.number="ruleform.fare2" class="form-control" aria-label="fare2" min="1" @blur="check" :disabled="isDisabled" required />
                      </div>
                    </template>
                  </div>
                  <!-- <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">E-mail (無法修改)</label>
                    <div class="col-sm-10">
                      <input type="email" v-model="ruleform.email" class="form-control" id="email" readonly />
                    </div>
                  </div> -->
                  <!-- <div class="row mb-3">
                    <div class="col-sm-12">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck1" required />
                        <label class="form-check-label" for="gridCheck1"> 我已了解並同意網站服務條款及隱私權政策 </label>
                      </div>
                    </div>
                  </div> -->
                  <div id="notifyHelpBlock" class="form-text">如所輸入的出發地、目的地及旅行日期為已存在之資料，系統會視為修改</div>
                  <button type="submit" class="btn btn-warning float-end">新增/修改</button>
                </form>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="modal fade" id="getlineNotifyModal" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">LINE Notify 綁定</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- <div class="row row-cols-1 g-4">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">若要綁定 LINE 話</h5>
                    <p class="card-text"></p>
                    <ul>
                      <li class="mt-2">Just.崩潰（以下稱本站）通知服務會使用到 LINE Notify 綁定，由於綁定過程中需要有 LINE 帳號，若你沒有 LINE 帳號，還煩請先行建立</li>
                      <li class="mt-2">如不清楚 LINE Notify 是怎樣的一個服務，可先前往LINE Notify <a href="https://notify-bot.line.me/zh_TW/" target="_blank">官方網站</a>了解相關運作</li>
                      <li class="mt-2">綁定過程中由於需要取得 notify 權限，會需要登入 LINE 帳號（僅做登入驗證使用），本站過程中並不會取得你在 LINE 上的個人資訊，也不會紀錄或存放任何你在 LINE 上的任何資訊</li>
                      <li class="mt-2">本站只會在你每次完成綁定後取得一組代碼 (TOKEN)，日後都只依據該代碼來進行訊息推播</li>
                      <li class="mt-2">當然哪天不想使用本站推播服務話，你可以隨時解除綁定</li>
                    </ul>
                    <div class="text-end">
                      <button type="button" class="btn btn-link btn-lg" @click="handleNotifyLogin">前往綁定</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">若要綁定 Telegram 話</h5>
                    <p class="card-text"></p>
                    <ul>
                      <li class="mt-2">需要請你加入 Just.崩潰（以下稱本站）所創立的機器人 <a href="https://t.me/JustbkBot" target="_blank">@JustbkBot</a>，由於加入過程中會需要有 Telegram 帳號，若你沒有 Telegram 帳號，還煩請先行建立</li>
                      <li class="mt-2">如不清楚 Telegram 是怎樣的一個平台，可先前往 Telegram <a href="https://web.telegram.org/" target="_blank">官方網站</a>了解相關資訊</li>
                      <li class="mt-2">
                        若已完成本站機器人加入到自己或是群組頻道內話，還請在對話視窗手動輸入<br />
                        <span v-html="tg_data"></span> 或是點一旁的複製按鈕 <button class="btn btn-primary btn-block" @click.prevent="cobycode(tg_connect_code)">複製</button> 並傳送該段訊息來完成綁定動作
                      </li>
                      <li class="mt-2">完成綁定後，「<b>半夜通知</b>」預設是 <span class="fs-5 text-danger">關閉</span> 狀態、「<b>站方推薦</b>」預設是 <span class="fs-5 text-success">開啟</span> 狀態，可依需要事後於管理清單上自行勾選</li>
                      <li class="mt-2">本站不會紀錄或存放任何你在 Telegram 上相關資訊（包括個人資料、聊天紀錄等）</li>
                      <li class="mt-2">本站只會在你每次完成綁定後取得一組 Chat ID，日後都只依據該 ID 來進行訊息推播</li>
                      <li class="mt-2">當然哪天不想使用本站推播服務話，你可以隨時解除綁定</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> -->

            <p>歡迎使用 LINE Notify 綁定，該功能需要有 LINE 帳號，若你沒有 LINE 帳號，還煩請先行建立</p>
            <p>功能說明</p>
            <ul>
              <li class="mt-2">如不清楚 LINE Notify 是怎樣的一個服務，可先前往LINE Notify <a href="https://notify-bot.line.me/zh_TW/" target="_blank">官方網站</a>了解相關運作</li>
              <li class="mt-2">綁定過程中由於需要取得 notify 權限，所以會需要登入 LINE 帳號（僅用於登入驗證），Just.崩潰（以下稱本站）並不會取得你在 LINE 上的個人資訊，本站也不會紀錄或存放任何你在 LINE 上的任何資訊</li>
              <li class="mt-2">本站只會在你每次完成綁定後取得一組代碼 (TOKEN)，日後都只依據該代碼來進行訊息推播</li>
              <li class="mt-2">當然哪天不想使用本站推播服務話，你可以隨時解除綁定</li>
            </ul>
            <div class="text-end">
              <button type="button" class="btn btn-link btn-lg" @click="handleNotifyLogin">前往綁定</button>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="getlineNotifyModal2" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">通知管理</h1>
            <!-- <h1 class="modal-title fs-5" id="staticBackdropLabel">E-mail 綁定</h1> -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- {{ rows5.members_role_take.role_type }} -->
            <div>
              <p>已設定/可新增的頻道</p>
              <tags-input :only-existing-tags="true" :typeahead-hide-discard="true" element-id="tags" wrapper-class="form-control" v-model="selectedTags" id-field="id" text-field="nickname" :existing-tags="rows4" :typeahead="true" @tag-added="onTagAdded" @tag-removed="onTagRemoved" typeahead-style="dropdown" placeholder="請輸入頻道名稱"></tags-input>
              <ul>
                <li class="mt-1 fs-6"><small>設定數不限，若要移除，點擊名稱旁的 x 即可移除</small></li>
              </ul>
              <hr />
            </div>
            <p>已登錄的 E-mail</p>
            <template v-if="modal_data">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="row">#狀態</th>
                      <th scope="col">E-mail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, key) in modal_data" :key="key">
                      <td>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="item.subscription_status" true-value="Subscribed" false-value="Unsubscribe" @change="updateSubscriptionStatus(item.subscription_status, item.email_list.id)" />
                          <label class="form-check-label" for="flexSwitchCheckDefault"><span v-html="formatSubscribedText(item.subscription_status)"></span></label>
                        </div>
                      </td>
                      <td scope="row">{{ item.email_list.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-if="!modal_data">
              <div class="alert alert-warning" role="alert">目前並沒有綁定任何票價追蹤</div>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
  import qs from "qs"
  import axios from "axios"
  import moment from "moment"
  import SearchFromV3 from "./SearchFrom_v3.vue"
  import VoerroTagsInput from "@voerro/vue-tagsinput"
  import Cookies from "js-cookie"
  // import Clipboard from "clipboard"

  export default {
    name: "VueSubscribeList",
    components: {
      SignIn: () => import("./SignIn.vue"),
      // UserProfilePage: () => import("./UserProfilePage.vue"),
      //   BootstrapTable: BootstrapTable,
      SearchFromV3,
      "tags-input": VoerroTagsInput,
    },
    computed: {
      showSection() {
        return this.showCreateSection
      },
    },
    // beforeMount() {
    //   this.$alert("讀取失敗2", "系統訊息", "error")
    // },
    mounted() {
      if (Cookies.get("login_token")) {
        const login_token = Cookies.get("login_token")
        this.checkLoginTokeVaild(login_token)
      }
      // console.log(this.$route.query)
      if (this.$route.query && this.$route.query.id && this.$route.query.op) {
        // if (!sessionStorage.getItem("is_email_confirm")) {
        //   this.$alert("10/1 起本功能僅提供通過帳號驗證之會員使用，如尚未完成者還請先完成驗證後再使用", "系統訊息", "error").then(() => {
        //     window.location.href = `/`
        //   })
        // }

        if (sessionStorage.getItem("role_type") !== "Sponsored") {
          this.$alert("11/1 起本功能僅提供付費會員使用", "系統訊息", "error").then(() => {
            window.location.href = `/`
          })
        }

        const query = this.$route.query
        const uuid = query.id
        const op = query.op
        this.queryform.uuid = uuid
        // this.tg_connect_code = "/connect " + uuid
        // this.tg_data = `<span class="fs-6 text-danger">${this.tg_connect_code}</span>`
        this.queryform.op = op
        this.initSubscribePage()

        const farehistoryModal = document.getElementById("farehistoryModal")
        if (farehistoryModal) {
          farehistoryModal.addEventListener("show.bs.modal", () => {})
        }

        const getlineNotifyModal = document.getElementById("getlineNotifyModal")
        if (getlineNotifyModal) {
          getlineNotifyModal.addEventListener("show.bs.modal", async () => {
            const me = this
            axios({
              method: "POST",
              url: `${me.$apiUrl2}getNotifyChannelCountByEmail`,
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/json",
              },
              data: {
                uuid: me.queryform.uuid,
              },
            }).then(function (response) {
              const res = response.data
              if (res.status === 503) {
                me.$alert("讀取失敗", "系統訊息", "error")
              }

              if (res.status === 201) {
                if (res.statusText === "Select") {
                  const channel_count = res.data
                  me.notify_channel_count = me.notify_channel_count - channel_count
                  if (me.notify_channel_count <= 0) {
                    me.$alert("已無額度新增頻道", "系統訊息", "success").then(() => {
                      window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
                    })
                  }
                }
                // me.selectedTags = res.data
              }
            })
          })
        }

        const getlineNotifyModal2 = document.getElementById("getlineNotifyModal2")
        if (getlineNotifyModal2) {
          getlineNotifyModal2.addEventListener("show.bs.modal", async (event) => {
            const button = event.relatedTarget
            const rule_id = button.getAttribute("data-bs-item-id")
            // const op = button.getAttribute("data-bs-item-op")
            // const dept_d = button.getAttribute("data-bs-dept_d")
            const me = this
            me.select_rule = rule_id
            // me.modal_data = op
            // console.log("select_rule", me.select_rule)
            // const me = this
            if (Cookies.get("login_token")) {
              const login_token = Cookies.get("login_token")
              this.checkLoginTokeVaild(login_token)
            }

            await axios({
              method: "POST",
              url: `${me.$apiUrl2}getNotifyChannelInfoByRule`,
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/json",
              },
              data: {
                rule_id: me.select_rule,
              },
            }).then(function (response) {
              // console.log(response.data)
              const res = response.data
              if (res.status === 503) {
                // me.$alert("刪除失敗", "系統訊息", "error")
              }

              if (res.status === 201) {
                // if (res.statusText === "Deleted") {
                //   me.$alert("刪除成功", "系統訊息", "success").then(() => {
                //     window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
                //   })
                // }
                me.selectedTags = res.data
              }
            })

            await axios({
              method: "POST",
              url: `${me.$apiUrl2}getNotifyEmailTakeByRule`,
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/json",
              },
              data: {
                rule_id: me.select_rule,
              },
            }).then(function (response) {
              // console.log(response.data)
              const res = response.data
              if (res.status === 503) {
                // me.$alert("刪除失敗", "系統訊息", "error")
              }

              if (res.status === 201) {
                // if (res.statusText === "Deleted") {
                //   me.$alert("刪除成功", "系統訊息", "success").then(() => {
                //     window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
                //   })
                // }
                me.modal_data = res.data
              }
            })
          })
        }
        // console.log(this.$route.query)
      } else {
        // this.$alert("讀取失敗1", "系統訊息", "error")
        this.showCreateSection = true
      }
    },
    data: function () {
      return {
        showCreateSection: false,
        notify_channel_count: 0,
        notify_rule_count: 3,
        subscription_status_text: "",
        selectedTags: [],
        select_rule: null,
        modal_data: [],
        tg_connect_code: "",
        form: {
          countryName1: [],
          cityName1: [],
          selectedItems1: "OW",
          // selectedItems2: [],
          // selectedItems3: [],
          dateRange: {},
          dateRange2: {},
        },
        countryName: {},
        cityName: "請選擇",
        selectedDay: "台北(桃園)|TPE",
        area: [
          {
            country: "台北(桃園)|TPE",
            city: ["札幌|CTS", "旭川|AKJ", "函館|HKD", "秋田|AXT", "花卷|HNA", "福島|FKS", "仙台|SDJ", "茨城|IBR", "東京成田|NRT", "東京羽田|HND", "新潟|KIJ", "小松|KMQ", "名古屋|NGO", "大阪|KIX", "岡山|OKJ", "高知|KCZ", "福岡|FUK", "佐賀|HSG", "沖繩|OKA", "-----", "首爾(仁川)|ICN", "釜山|PUS", "濟州|CJU", "-----", "澳門|MFM", "-----", "富國島|PQC"],
          },
          {
            country: "台中|RMQ",
            city: ["澳門|MFM"],
          },
          {
            country: "高雄|KHH",
            city: [
              "東京成田|NRT",
              "大阪|KIX",
              "名古屋|NGO",
              "福岡|FUK",
              "沖繩|OKA",
              "-----",
              // "首爾(仁川)|ICN",
              "首爾(金浦)|GMP",
              // "釜山|PUS",
              "-----",
              "澳門|MFM",
              // "-----",
              // "曼谷(廊曼)|DMK",
              // "沖繩|OKA"
            ],
          },
          {
            country: "-----",
            city: [],
          },
          {
            country: "札幌|CTS",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "旭川|AKJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "函館|HKD",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "旭川|AKJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "秋田|AXT",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "花卷|HNA",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "福島|FKS",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "仙台|SDJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "茨城|IBR",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "東京成田|NRT",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "東京羽田|HND",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "新潟|KIJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "小松|KMQ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "名古屋|NGO",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "大阪|KIX",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "岡山|OKJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "高知|KCZ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "福岡|FUK",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "佐賀|HSG",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "沖繩|OKA",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "-----",
            city: [],
          },
          {
            country: "首爾(仁川)|ICN",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "首爾(金浦)|GMP",
            city: ["高雄|KHH"],
          },
          {
            country: "釜山|PUS",
            city: ["台北(桃園)|TPE"],
          },
          // {
          //   country: "大邱|TAE",
          //   city: ["台北(桃園)|TPE"],
          // },
          {
            country: "濟州|CJU",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "-----",
            city: [],
          },
          {
            country: "澳門|MFM",
            city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
          },
          // {
          //   country: "-----",
          //   city: [],
          // },
          // {
          //   country: "曼谷(廊曼)|DMK",
          //   city: ["高雄|KHH"],
          // },
          {
            country: "-----",
            city: [],
          },
          {
            country: "富國島|PQC",
            city: ["台北(桃園)|TPE"],
          },
        ],
        isDisabled: false,
        awesome: true,
        rows: [],
        rows2: [],
        rows3: [],
        rows4: [],
        rows5: [],

        queryform: {
          uuid: "",
          op: "",
        },
        notifytakeform: {
          channel_list: [],
        },
        ruleform: {
          airline: "",
          mode: "now",
          fare_base: "under",
          fare1: 1,
          fare2: null,
          email: "",
          dept: "",
          arri: null,
          dept_d: "",
          arri_d: null,
          travel_type: "OW",
          unit: "d",
          value: "1",
          currency: "TWD",
          flight_no: "",
          // dateRange2: {},
        },
      }
    },
    // beforeRouteEnter(to, from, next) {
    //   // console.log(to, from, next)
    //   // const resultPath = from.name.split("___")[0]
    //   next((vm) => {
    //     // console.log(vm)
    //     // if (resultPath !== "member-name") {
    //     // vm.$store.commit(`Member/${mutations.MEMBER_RESET_NEWMEMBERDATA}`)
    //     // }
    //     vm.checkAuth()
    //     // console.log(res)
    //     // if (res.status === 503) {
    //     //   vm.$alert("新增/修改失敗", "系統訊息", "error")
    //     // }
    //     // if (res.status === 201) {
    //     //   if (res.statusText === "Created") {
    //     //     vm.$alert("新增成功", "系統訊息", "success")
    //     //   }
    //     //   if (res.statusText === "Updated") {
    //     //     vm.$alert("修改成功", "系統訊息", "warning")
    //     //   }
    //     // }
    //     // vm.$alert("修改成功", "系統訊息", "warning")
    //   })
    // },
    methods: {
      cobycode(text) {
        const me = this
        navigator.clipboard.writeText(text).then(() => {
          me.$alert(`已成功複製，煩請記得要貼到 Telegram 對話視窗並傳送`, "系統訊息", "success")
          // this.$StatusMsg(true, "複製", "您已成功複製優惠碼!")
        })
      },
      notify_switch_status(type, uuid, checked) {
        console.log(type, uuid, checked)

        const me = this
        const json_d = {
          type: type,
          uuid: uuid,
          checked: checked,
        }

        axios({
          method: "PATCH",
          url: `${me.$apiUrl2}updateNotifySwitchStatus`,
          data: json_d,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
        }).then(function (response) {
          const res = response.data
          if (res.status === 201) {
            window.location.reload()
          }
          if (res.status === 503) {
            me.$alert(`若須取消，還請於 ${res.data} 後再執行`, "系統訊息", "error").then(() => {
              window.location.reload()
            })
          }
        })
      },
      checkLoginTokeVaild(login_token) {
        const me = this
        const json_d = { token: login_token }

        axios({
          method: "POST",
          url: `${me.$apiUrl2}checkLoginTokeVaild`,
          data: json_d,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
        }).then(function (response) {
          const res = response.data

          if (res.status === 201) {
            if (res.statusText === "Select") {
              me.user_uuid = res.data.id
              sessionStorage.setItem("uuid", res.data.id)
              sessionStorage.setItem("is_used", res.data.user_take.is_used)
              sessionStorage.setItem("is_email_confirm", res.data.user_take.is_email_confirm)
              Cookies.set("uuid", res.data.id, { secure: true })
              Cookies.set("is_used", res.data.user_take.is_used, { secure: true })
              Cookies.set("is_email_confirm", res.data.user_take.is_email_confirm, { secure: true })

              if (me.$route.name === "login") {
                window.location.href = `/Subscribe?id=${res.data.id}&op=notify_manage`
              }
            }
          }
          if (res.status === 503) {
            me.main_page_switch = false
            me.user_uuid = null
            window.location.href = `/Login`
          }
        })
      },
      async updateSubscriptionStatus(subscription_status, email_id) {
        const me = this

        if (subscription_status === "Subscribed") {
          me.subscription_status_text = "Subscribed"
          // me.toggle === 1
        }

        if (subscription_status === "Unsubscribe") {
          me.subscription_status_text = "Unsubscribe"
          // me.toggle === false
        }

        await axios({
          method: "PATCH",
          url: `${me.$apiUrl2}updateSubscriptionStatus`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            id: email_id,
            rule_id: me.select_rule,
            subscription_status: me.subscription_status_text,
          },
        }).then(function (response) {
          // console.log(response.data)
          const res = response.data
          if (res.status === 503) {
            me.$alert("變更失敗", "系統訊息", "error")
          }

          if (res.status === 201) {
            if (res.statusText === "Updated") {
              //   me.$alert("刪除成功", "系統訊息", "success").then(() => {
              //     window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
              //   })
              if (res.data === "Subscribed") {
                // me.subscription_status = false
                // console.log("subscription_status2", me.subscription_status)
              }

              if (res.data === "Unsubscribe") {
                // me.subscription_status = true
                // console.log("subscription_status3", me.subscription_status)
              }
            }
            // me.selectedTags = res.data
          }
        })
      },
      async onTagAdded(slug) {
        // console.log(slug)
        // console.log(`Tag added: ${slug}`)

        const me = this
        const id = slug.id

        await axios({
          method: "PATCH",
          url: `${me.$apiUrl2}addNotifyChannelTake`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            id: id,
            rule_id: me.select_rule,
          },
        }).then(function (response) {
          // console.log(response.data)
          const res = response.data
          if (res.status === 503) {
            // me.$alert("刪除失敗", "系統訊息", "error")
          }

          if (res.status === 201) {
            // if (res.statusText === "Deleted") {
            //   me.$alert("刪除成功", "系統訊息", "success").then(() => {
            //     window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
            //   })
            // }
            // me.selectedTags = res.data
          }
        })
      },
      async onTagRemoved(slug) {
        // console.log(slug)
        // console.log(`Tag removed: ${slug}`)

        const me = this
        const id = slug.id

        await axios({
          method: "PATCH",
          url: `${me.$apiUrl2}removeNotifyChannelTake`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            id: id,
            rule_id: me.select_rule,
          },
        }).then(function (response) {
          // console.log(response.data)
          const res = response.data
          if (res.status === 503) {
            // me.$alert("刪除失敗", "系統訊息", "error")
          }

          if (res.status === 201) {
            // if (res.statusText === "Deleted") {
            //   me.$alert("刪除成功", "系統訊息", "success").then(() => {
            //     window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
            //   })
            // }
            // me.selectedTags = res.data
          }
        })
      },
      getRuleContent1(rule_travel_type, rule_dept_d, rule_arri_d, rule_dept, rule_arri) {
        let msg = ""

        if (rule_travel_type === "OW") {
          msg += `<span class="badge text-bg-warning fs-6">單程</span> 出發日 ${rule_dept_d} <br /> ${rule_dept} 飛往 ${rule_arri}`
        }

        if (rule_travel_type === "RT") {
          msg += `<span class="badge text-bg-success fs-6">來回</span> 去程日 ${rule_dept_d} 回程日 ${rule_arri_d} <br /> ${rule_dept} 往返 ${rule_arri}`
        }

        return msg
      },
      getRuleContent2(rule_mode, rule_currency, rule_fare1, rule_fare2, rule_fare_base) {
        let msg = ""

        if (rule_mode === "now") {
          if (rule_fare_base === "none") {
            msg += `<span class="badge text-bg-info fs-6">目前</span> 不管高或低一律通知`
          } else {
            msg += `<span class="badge text-bg-info fs-6">目前</span> 最近一次價格`
            if (rule_fare_base === "between") {
              msg += `介於 ${rule_fare1} ~ ${rule_fare2}`
            }

            if (rule_fare_base === "above") {
              msg += `高於 ${rule_fare1}`
            }

            if (rule_fare_base === "under") {
              msg += `低於 ${rule_fare1}`
            }
            msg += `<br /><small>*單程未稅 ${rule_currency}</small>`
          }
        }

        if (rule_mode === "trend") {
          if (rule_fare_base === "none") {
            msg += `<span class="badge text-bg-primary fs-6">趨勢</span> 不管高或低一律通知`
          } else {
            msg += `<span class="badge text-bg-primary fs-6">趨勢</span> 跟上次價格相比`
            if (rule_fare_base === "between") {
              msg += `介於 ${rule_fare1} ~ ${rule_fare2}`
            }

            if (rule_fare_base === "above") {
              msg += `高於 ${rule_fare1}`
            }

            if (rule_fare_base === "under") {
              msg += `低於 ${rule_fare1}`
            }
            msg += `<br /><small>*單程未稅 ${rule_currency}</small>`
          }
        }

        return msg
      },
      getD2(val) {
        this.form.dateRange2 = val
        // this.ruleform.dateRange2 = val
        if (Array.isArray(val)) {
          this.ruleform.dept_d = val[0]
          this.ruleform.arri_d = val[1]
        } else {
          this.ruleform.dept_d = val
          this.ruleform.arri_d = null
        }
        // console.log(Array.isArray(val))
      },
      selectCity(value) {
        this.ruleform.dept = ""
        const selectedIndex = value.target.options.selectedIndex
        this.form.countryName1 = this.area[selectedIndex].country
        this.ruleform.dept = this.area[selectedIndex].country.split("|")[1]
      },
      setCountryCity(value) {
        this.ruleform.arri = null
        this.form.cityName1 = ""
        const selectedIndex = value.target.options.selectedIndex
        const countryName = this.form.countryName1
        const area = this.area
        area.forEach((e) => {
          if (e.country === countryName) {
            this.form.cityName1 = e.city[selectedIndex]
            this.ruleform.arri = e.city[selectedIndex].split("|")[1]
          }
        })
      },
      open_fare(flag, mode) {
        this.awesome = flag
        if (mode !== "between") {
          //   if (me.ruleform.fare_base !== "between") {
          //     me.ruleform.fare1 = fareAmount
          //     me.ruleform.fare2 = avg_7d
          //   } else {
          //     me.ruleform.fare1 = fareAmount
          //     me.ruleform.fare2 = ""
          //   }
        }
        this.isDisabled = false
      },
      disable_fare() {
        this.ruleform.fare1 = null
        this.ruleform.fare2 = null
        this.isDisabled = true
      },
      check() {
        const fare1 = this.ruleform.fare1
        const fare2 = this.ruleform.fare2
        if (fare2 < fare1) {
          // alert("後面的設定價必須大於前面設定價")
          this.$alert("後面的設定價必須大於前面設定價", "系統訊息", "error")
        }
      },
      async getFormValues(e) {
        e.preventDefault()

        const me = this
        await axios({
          method: "PATCH",
          url: `${me.$apiUrl2}addEmailRule`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            ruleform: this.ruleform,
          },
        }).then(function (response) {
          // console.log(response.data)
          const res = response.data
          if (res.status === 503) {
            me.$alert("新增/修改失敗", "系統訊息", "error")
          }

          if (res.status === 201) {
            if (res.statusText === "Created") {
              me.$alert("新增成功", "系統訊息", "success").then(() => {
                window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
              })
            }
            if (res.statusText === "Updated") {
              me.$alert("修改成功", "系統訊息", "warning").then(() => {
                window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
              })
            }
          }
        })
      },
      // checkAuth() {
      //   // console.log("uuid", uuid)
      //   const me = this
      //   axios({
      //     method: "POST",
      //     url: `${me.$apiUrl2}checkSubscribeByEmail`,
      //     headers: {
      //       Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
      //       "Content-Type": "application/json",
      //     },
      //     data: {
      //       queryform: me.queryform,
      //     },
      //   }).then(function (response) {
      //     console.log("1", response.data)
      //     const res = response.data
      //     if (res.status === 503) {
      //       me.$alert("新增/修改失敗1", "系統訊息", "error")
      //     }
      //   })
      // },
      initSubscribePage() {
        const me = this
        axios({
          method: "POST",
          url: `${me.$apiUrl2}getSubscribeByEmail`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            queryform: me.queryform,
          },
        }).then(function (response) {
          const res = response.data
          if (res.status === 503) {
            me.$alert("查無此資訊", "系統訊息", "error").then(() => {
              window.location.href = `/`
            })
          }

          if (res.status === 201) {
            me.rows = res.data
            me.ruleform.email = me.rows.email
            if (me.$route.query.op === "notify_manage") {
              me.rows2 = res.notify_manage_data
              me.rows3 = res.channel_info
              me.rows4 = res.channel_info.data

              if (res.sponsor_result) {
                me.$alert("感謝你長期以來支持本站，目前已將身份提升為贊助會員", "系統訊息", "success").then(() => {
                  window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
                })
              }

              if (res.member_info.data.legth === 0) {
                me.rows5 = {}
                me.notify_channel_count = 0
                me.notify_rule_count = 3
              } else {
                me.rows5 = res.member_info.data
                me.notify_channel_count = res.member_info.data.notify_user_take.notify_channel_count
                me.notify_rule_count = res.member_info.data.notify_user_take.notify_rule_count
              }
            }

            axios({
              method: "POST",
              url: `${me.$apiUrl2}getNotifyChannelCountByEmail`,
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/json",
              },
              data: {
                uuid: me.queryform.uuid,
              },
            }).then(function (response) {
              const res = response.data
              if (res.status === 503) {
                me.$alert("讀取失敗", "系統訊息", "error")
              }

              if (res.status === 201) {
                if (res.statusText === "Select") {
                  const channel_count = res.data
                  me.notify_channel_count = me.notify_channel_count - channel_count
                }
              }
            })

            axios({
              method: "POST",
              url: `${me.$apiUrl2}getNotifyRuleCountByEmail`,
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/json",
              },
              data: {
                uuid: me.queryform.uuid,
              },
            }).then(function (response) {
              const res = response.data
              if (res.status === 503) {
                me.$alert("讀取失敗", "系統訊息", "error")
              }

              if (res.status === 201) {
                if (res.statusText === "Select") {
                  const rule_count = res.data
                  me.notify_rule_count = me.notify_rule_count - rule_count
                }
              }
            })
          }
        })
      },
      async unsubscribeNotify(uuid) {
        const me = this

        me.$confirm("確定取消？").then(async () => {
          await axios({
            method: "PATCH",
            url: `${me.$apiUrl2}unsubscribeNotify`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
            data: {
              uuid: uuid,
            },
          }).then(function (response) {
            // console.log(response.data)
            const res = response.data
            if (res.status === 503) {
              me.$alert("取消失敗", "系統訊息", "error")
            }

            if (res.status === 201) {
              if (res.statusText === "Deleted") {
                me.$alert("取消成功", "系統訊息", "success").then(() => {
                  window.location.href = `/`
                })
              }
            }
          })
        })
      },
      async removeNotifyChannel(uuid) {
        const me = this

        me.$confirm("確定解除這個頻道的綁定？").then(async () => {
          await axios({
            method: "PATCH",
            url: `${me.$apiUrl2}removeNotifyChannel`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
            data: {
              uuid: uuid,
            },
          }).then(function (response) {
            // console.log(response.data)
            const res = response.data
            if (res.status === 503) {
              me.$alert("綁定解除失敗", "系統訊息", "error")
            }

            if (res.status === 201) {
              if (res.statusText === "Deleted") {
                me.$alert("解除成功", "系統訊息", "success").then(() => {
                  window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
                })
              }
            }
          })
        })
      },
      async removeNotifyRule(uuid) {
        const me = this

        me.$confirm("確定刪除這組規則？").then(async () => {
          await axios({
            method: "PATCH",
            url: `${me.$apiUrl2}removeNotifyRule`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
            data: {
              uuid: uuid,
            },
          }).then(function (response) {
            // console.log(response.data)
            const res = response.data
            if (res.status === 503) {
              me.$alert("刪除失敗", "系統訊息", "error")
            }

            if (res.status === 201) {
              if (res.statusText === "Deleted") {
                me.$alert("刪除成功", "系統訊息", "success").then(() => {
                  window.location.href = `/Subscribe?id=${me.queryform.uuid}&op=notify_manage`
                })
              }
            }
          })
        })
      },
      handleNotifyLogin() {
        const line_auth = "https://notify-bot.line.me/oauth/authorize"
        const auth_params = {
          response_type: "code",
          client_id: process.env.VUE_LINE_CLIENT_ID,
          redirect_uri: `${this.$webUrl}notify_callback`,
          state: this.queryform.uuid,
          scope: "notify",
        }
        const paramsString = qs.stringify(auth_params)
        window.location.href = `${line_auth}?${paramsString}`
        // console.log(`${line_auth}?${paramsString}`)
      },
      formatSubscribedText(data) {
        if (data == "Subscribed") return '<h5><span class="badge text-bg-success">通知</span></h5>'

        if (data == "Unsubscribe") return '<h5><span class="badge text-bg-secondary">取消通知</span></h5>'
      },
    },
    filters: {
      getWeekDay: function (date) {
        const weeks = ["", "一", "二", "三", "四", "五", "六", "日"]
        return weeks[moment(date).isoWeekday()]
      },
      formatDate: function (date) {
        return moment(date).format("YYYY/MM/DD HH:mm")
      },
      // formatSubscribedText: function (data) {
      //   if (data == "Subscribed") return '<span class="badge text-bg-success">通知</span>'

      //   if (data == "Unsubscribe") return '<span class="badge text-bg-secondary">取消通知</span>'
      // },
    },
  }
</script>
<style>
  @import url("https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css");
  body {
    /* background-color: #87baa5; */
    background-color: #fafff0;
  }
</style>
