<template>
  <div class="container-fluid">
    <div class="row">
      <div class="row row-cols-1 mt-5">
        <div class="col">
          <div class="alert alert-danger mt-1" role="alert">網站服務調整 (11/1起) <a href="https://www.facebook.com/just.bk.me/posts/868366018794844" target="_blank">見詳細公告</a></div>
        </div>
      </div>
      <div class="col-lg-6">
        <!-- <div class="row row-cols-1 mt-1"> -->
        <main class="mt-3 w-auto m-auto">
          <UserProfilePage :memberProfile="rows5"></UserProfilePage>
        </main>
        <!-- </div> -->
      </div>
      <div class="col-lg-6">
        <main class="mt-3 w-auto m-auto">
          <vue-good-table
            :line-numbers="false"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              mode: 'pages',
            }"
            :columns="columns"
            :rows="rows"
            styleClass="vgt-table striped" />
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  // import qs from "qs"
  import axios from "axios"
  import moment from "moment"
  // import SearchFromV3 from "./SearchFrom_v3.vue"
  // import VoerroTagsInput from "@voerro/vue-tagsinput"
  import GlobalVar from "@/mixins/global_var.js"
  import Cookies from "js-cookie"
  // import Clipboard from "clipboard"

  export default {
    name: "VueUserProfile",
    mixins: [GlobalVar],
    components: {
      UserProfilePage: () => import("./UserProfilePage.vue"),
    },
    computed: {
      // showSection() {
      //   return this.showCreateSection
      // },
    },
    // beforeMount() {
    //   this.$alert("讀取失敗2", "系統訊息", "error")
    // },
    mounted() {
      if (Cookies.get("login_token")) {
        const login_token = Cookies.get("login_token")
        this.checkLoginTokeVaild(login_token)
      }
      // console.log(this.$route.query)
      // if (this.$route.query && this.$route.query.id && this.$route.query.op) {
      if (!sessionStorage.getItem("uuid")) {
        window.location.href = `/Login?url=/Profile`
      } else {
        this.queryform.uuid = sessionStorage.getItem("uuid")
        this.queryform.op = "nu"
        this.initSubscribePage()
      }

      // if (sessionStorage.getItem("role_type") !== "Sponsored") {
      //   this.$alert("11/1 起本功能僅提供付費會員使用", "系統訊息", "error").then(() => {
      //     window.location.href = `/`
      //   })
      // }

      // console.log(this.$route.query)
      // } else {
      //   // this.$alert("讀取失敗1", "系統訊息", "error")
      //   this.showCreateSection = true
      // }
    },
    data: function () {
      return {
        columns: [
          {
            label: "紀錄月份",
            // width: "150px",
            field: "query_d",
            // filterOptions: {
            //   styleClass: "class1", // class to be added to the parent th element
            //   enabled: true, // enable filter for this column
            //   placeholder: "", // placeholder for filter input
            //   filterValue: "", // initial populated value for this filter
            //   // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
            //   // filterFn: this.columnFilterFn, //custom filter function that
            //   // trigger: "enter", //only trigger on enter not on keyup
            // },
          },
          {
            label: "懶人包 ref",
            // width: "150px",
            field: "ref",
            // filterOptions: {
            //   styleClass: "class1", // class to be added to the parent th element
            //   enabled: true, // enable filter for this column
            //   placeholder: "", // placeholder for filter input
            //   filterValue: "", // initial populated value for this filter
            //   // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
            //   // filterFn: this.columnFilterFn, //custom filter function that
            //   // trigger: "enter", //only trigger on enter not on keyup
            // },
          },
          // {
          //   label: "檢視次數",
          //   // width: "150px",
          //   field: "count",
          // },
        ],
        rows: [],
        rows5: [],
        queryform: {
          uuid: "",
          op: "",
        },
      }
    },

    methods: {
      // checkLoginTokeVaild(login_token) {
      //   const me = this
      //   const json_d = { token: login_token }

      //   axios({
      //     method: "POST",
      //     url: `${me.$apiUrl2}checkLoginTokeVaild`,
      //     data: json_d,
      //     headers: {
      //       Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
      //       "Content-Type": "application/json",
      //     },
      //   }).then(function (response) {
      //     const res = response.data

      //     if (res.status === 201) {
      //       if (res.statusText === "Select") {
      //         me.user_uuid = res.data.id
      //         sessionStorage.setItem("uuid", res.data.id)
      //         sessionStorage.setItem("is_used", res.data.user_take.is_used)
      //         sessionStorage.setItem("is_email_confirm", res.data.user_take.is_email_confirm)
      //         Cookies.set("uuid", res.data.id, { secure: true })
      //         Cookies.set("is_used", res.data.user_take.is_used, { secure: true })
      //         Cookies.set("is_email_confirm", res.data.user_take.is_email_confirm, { secure: true })

      //         if (me.$route.name === "login") {
      //           window.location.href = `/Subscribe?id=${res.data.id}&op=notify_manage`
      //         }
      //       }
      //     }
      //     if (res.status === 503) {
      //       me.main_page_switch = false
      //       me.user_uuid = null
      //       window.location.href = `/Login`
      //     }
      //   })
      // },

      initSubscribePage() {
        const me = this
        axios({
          method: "POST",
          url: `${me.$apiUrl2}getSubscribeByEmail`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            queryform: me.queryform,
          },
        }).then(function (response) {
          const res = response.data
          if (res.status === 503) {
            me.$alert("查無此資訊", "系統訊息", "error").then(() => {
              window.location.href = `/`
            })
          }

          if (res.status === 201) {
            me.rows = res.data
            // me.ruleform.email = me.rows.email
            if (res.member_info.data.legth === 0) {
              me.rows5 = {}
              // me.notify_channel_count = 0
              // me.notify_rule_count = 3
            } else {
              me.rows5 = res.member_info.data
              // me.notify_channel_count = res.member_info.data.notify_user_take.notify_channel_count
              // me.notify_rule_count = res.member_info.data.notify_user_take.notify_rule_count

              axios({
                method: "POST",
                url: `${me.$apiUrl}querylowfare_usage`,
                data: {
                  queryform: me.queryform,
                },
                headers: {
                  Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                  "Content-Type": "application/json",
                },
              }).then(function (response) {
                me.rows = response.data
              })
            }
            // if (me.$route.query.op === "notify_manage") {
            //   // me.rows2 = res.notify_manage_data
            //   // me.rows3 = res.channel_info
            //   // me.rows4 = res.channel_info.data

            if (res.sponsor_result) {
              me.$alert("感謝你長期以來支持本站，目前已將身份提升為贊助會員", "系統訊息", "success")
            }

            //   if (res.member_info.data.legth === 0) {
            //     me.rows5 = {}
            //     // me.notify_channel_count = 0
            //     // me.notify_rule_count = 3
            //   } else {
            //     me.rows5 = res.member_info.data
            //     // me.notify_channel_count = res.member_info.data.notify_user_take.notify_channel_count
            //     // me.notify_rule_count = res.member_info.data.notify_user_take.notify_rule_count
            //   }
            // }
          }
        })
      },
    },
    filters: {
      getWeekDay: function (date) {
        const weeks = ["", "一", "二", "三", "四", "五", "六", "日"]
        return weeks[moment(date).isoWeekday()]
      },
      formatDate: function (date) {
        return moment(date).format("YYYY/MM/DD HH:mm")
      },
      // formatSubscribedText: function (data) {
      //   if (data == "Subscribed") return '<span class="badge text-bg-success">通知</span>'

      //   if (data == "Unsubscribe") return '<span class="badge text-bg-secondary">取消通知</span>'
      // },
    },
  }
</script>
<style>
  @import url("https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css");
  body {
    /* background-color: #87baa5; */
    background-color: #fafff0;
  }
</style>
