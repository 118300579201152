import dotenv from "dotenv"
dotenv.config()

import "jquery"
import Vue from "vue"
import App from "./App.vue"
import VueGtag from "vue-gtag"
// import pinia from "./store"

// import { createPinia, PiniaVuePlugin } from "pinia"

// Vue.use(PiniaVuePlugin)
// const pinia = createPinia()

// import "regenerator-runtime"

import VueGoodTablePlugin from "vue-good-table"
import "vue-good-table/dist/vue-good-table.css"
// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css'

import "bootstrap"
// import * as bootstrap from "bootstrap"
// window.bootstrap = bootstrap
import "bootstrap/dist/css/bootstrap.css"
// import "@/scss/main.scss" // 引入scss
// Vue.use(bootstrap)

import axios from "axios"
function generateRandomString(num) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result1 = " "
  const charactersLength = characters.length
  for (let i = 0; i < num; i++) {
    result1 += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result1
}
axios.defaults.headers.common["X-CSRF-TOKEN"] = `${generateRandomString(20)}`

import VueAxios from "vue-axios"
import VueMeta from "vue-meta"

import VueApexCharts from "vue-apexcharts"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faPlaneArrival, faPlaneDeparture, faSuitcase } from "@fortawesome/free-solid-svg-icons"

import SignIn from "./components/SignIn.vue"
import SignUp from "./components/SignUp.vue"
import SubscriptionTable from "./components/SubscriptionTable.vue"
import Chart from "./components/Chart.vue"
import SearchHistory from "./components/SearchHistory.vue"
import SearchHistory2020 from "./components/SearchHistory2020.vue"
// import Calendar from "./components/CalendarTable.vue"

import LazyBag from "./components/LazyBag.vue"
import LowFare from "./components/LowFare.vue"
import StatView from "./components/StatView.vue"
// import FareMap from "./components/FareMap.vue"
// import MonthlyTable from "./components/MonthlyTable.vue"
// import CompareMonthlyTable from "./components/CompareMonthlyTable.vue"
// import CompareMonthlyTableByWeek from "./components/CompareMonthlyTableByWeek.vue"
import SubscribeList from "./components/SubscribeList.vue"
import NotifyCallBack from "./components/NotifyCallBack.vue"
import GoLink from "./components/GoLink.vue"
import PrivacyPolicy from "./components/PrivacyPolicy.vue"
import TermsOfService from "./components/TermsOfService.vue"
import CheckEmailConfirmCode from "./components/CheckEmailConfirmCode.vue"
import SignOut from "./components/SignOut.vue"
import ResetPwdPage from "./components/ResetPwdPage.vue"
import ThreadsCallBack from "./components/ThreadsCallBack.vue"
import UserProfile from "./components/UserProfile.vue"

import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)

import VueRouter from "vue-router"
Vue.use(VueRouter)
// import { $, jQuery } from "jquery"

import Cookies from "js-cookie"
Vue.prototype.$cookie = Cookies
// window.$ = $
// window.jQuery = jQuery

// import "bootstrap-table/dist/bootstrap-table.js"
// import BootstrapTable from "bootstrap-table/dist/bootstrap-table-vue.min.js"
// Vue.component("BootstrapTable", BootstrapTable)
// import VueToast from "vue-toast-notification"
// import "vue-toast-notification/dist/theme-bootstrap.css"
// Vue.use(VueToast)

library.add(faPlaneArrival, faPlaneDeparture, faSuitcase)
Vue.use(VueGoodTablePlugin)
// Vue.use(Vuesax)
Vue.use(VueAxios, axios)
Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: { id: "G-RS3EQ6ZXR5" },
})
Vue.use(VueApexCharts)

Vue.component("apexchart", VueApexCharts)
Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.config.productionTip = false

Vue.prototype.$apiUrl = `https://justbk-api-4cqv3jfjdq-de.a.run.app/`
Vue.prototype.$apiUrl2 = `https://justbk-api-dev-4cqv3jfjdq-de.a.run.app/`
// Vue.prototype.$apiUrl = `http://localhost:8000/`
// Vue.prototype.$apiUrl2 = `http://localhost:8011/`

// Vue.prototype.$webUrl = `http://localhost:8080/`
Vue.prototype.$webUrl = `https://just.bengkuei.me/`

let routes = [
  { path: "/", component: Chart, name: "index" },
  { path: "/AdvSearch", component: SearchHistory, name: "advSearch" },
  { path: "/SearchHistory", component: SearchHistory2020 },
  { path: "/Chart", component: Chart, name: "chart" },
  { path: "/SubscriptionPlan", component: SubscriptionTable },
  { path: "/Login", component: SignIn, name: "login" },
  { path: "/NewUser", component: SignUp, name: "newUser" },
  { path: "/LazyBag", component: LazyBag },
  { path: "/LowFare", component: LowFare },
  { path: "/Stat", component: StatView, name: "stat" },
  { path: "/notify_callback", component: NotifyCallBack },
  { path: "/Subscribe", component: SubscribeList, name: "subscribe" },
  { path: "/Profile", component: UserProfile, name: "profile" },
  { path: "/go", component: GoLink, name: "goLink" },
  // { path: "/go/:id", component: GoLink, props: true },
  { path: "/PrivacyPolicy", component: PrivacyPolicy, name: "privacyPolicy" },
  { path: "/TermsOfService", component: TermsOfService, name: "termsOfService" },
  { path: "/CheckEmailConfirmCode", component: CheckEmailConfirmCode, name: "checkEmailConfirmCode" },
  { path: "/Logout", component: SignOut, name: "logout" },
  { path: "/ResetPwdPage", component: ResetPwdPage, name: "resetPwd" },
  { path: "/threads_callback", component: ThreadsCallBack },
  // { path: "/Calendar", component: Calendar },
  // { path: "/MonthlyTable", component: MonthlyTable },
  // { path: "/Map/:tag", component: FareMap },
  // { path: "/CompareMonthlyTable", component: CompareMonthlyTable },
  // { path: "/CompareMonthlyTableByWeek", component: CompareMonthlyTableByWeek },
]

let router = new VueRouter({
  mode: "history",
  routes,
})

new Vue({
  router,
  // pinia,
  render: (h) => h(App),
}).$mount("#app")
