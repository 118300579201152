var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('main',{staticClass:"mt-3 w-auto m-auto"},[_c('UserProfilePage',{attrs:{"memberProfile":_vm.rows5}})],1)]),_c('div',{staticClass:"col-lg-6"},[_c('main',{staticClass:"mt-3 w-auto m-auto"},[_c('vue-good-table',{attrs:{"line-numbers":false,"search-options":{
            enabled: false,
          },"pagination-options":{
            enabled: true,
            mode: 'pages',
          },"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-cols-1 mt-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"alert alert-danger mt-1",attrs:{"role":"alert"}},[_vm._v("網站服務調整 (11/1起) "),_c('a',{attrs:{"href":"https://www.facebook.com/just.bk.me/posts/868366018794844","target":"_blank"}},[_vm._v("見詳細公告")])])])])
}]

export { render, staticRenderFns }